<template lang="html">
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo" />
            <div class="profile wrapper">
                <div class="profile-header-block">
                    <div class="payments__container">
                        <div class="payments__add">
                            <button
                                default
                                class="btn-base btn-main button payments__add-btn"
                                @click.prevent="onAddMethod"
                            >
                                Add new payment
                            </button>
                        </div>
                        <div
                            v-if="loading"
                            class="radius-loader--wrapper"
                        >
                            <div
                                class="background-for-radius-loader"
                            >
                                <img
                                    src="@/assets/img/svg/animation/LoaderRadius.svg"
                                    alt="loader"
                                >
                            </div>
                        </div>
                        <div
                            v-else-if="!loading && connectedMethods.length > 0"
                            class="payments__list"
                        >
                            <template
                                v-for="(method, index) in connectedMethods"
                            >
                                <payment-method-item
                                    :key="method.id"
                                    :index="index"
                                    :fields="method.fields"
                                    :type="getType(method.type)"
                                    :not-editable="method.type === 'payoneer'"
                                    @edit="onEdit(method.id, method.type)"
                                    @delete="onDelete(method.id)"
                                />
                            </template>
                        </div>
                        <no-result v-else-if="!loading && connectedMethods.length === 0" />
                    </div>
                </div>
            </div>
            <confirm-modal ref="confirm-modal" />
        </template>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
import HeaderContent from '@/components/HeaderContent';
import { eventBus } from '@/helpers/event-bus/'
import {
    getPaymentsListAvailable,
    getPaymentsListConnected,
    deleteWithdrawalMethod
} from '@/services/payments';
import NoResult from '@/components/common/NoResult';

import { createNamespacedHelpers } from 'vuex';
import {
    ACTION_UPDATE_PAYMENT_DETAILS
} from '@/store/modules/writer/action-types'
import PaymentMethodItem from '@/components/account-settings/ProfileAddress/PaymentMethodItem.vue';
import ConfirmModal from '@/components/modals/ConfirmModal';

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer')

export default {
    name: 'Index',
    components: {
        MainLayout,
        HeaderContent,
        PaymentMethodItem,
        ConfirmModal,
        NoResult
    },
    metaInfo: {
        title: 'Payment Method'
    },
    data() {
        return {
            showModal: false,
            types: [],
            selectedType: 'wire',
            fields: null,
            connectedMethods: [],
            loading: true
        }
    },
    computed: {
        headerInfo() {
            return {
                title: 'Withdrawal Methods',
                className: 'header-content__account-setting',
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    },
                    {
                        title: 'Profile',
                        url: '/cp/profile'
                    }
                ]
            }
        }
    },
    async created() {
        await this.getTypes()
        await this.getMethods()
    },
    methods: {
        ...mapWriterActions([
            ACTION_UPDATE_PAYMENT_DETAILS
        ]),
        async getPaymentsListAvailable() {
            let data
            try {
                data = await getPaymentsListAvailable()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
            return data
        },
        async getPaymentsListConnected() {
            let data
            try {
                data = await getPaymentsListConnected()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
            return data
        },
        async deleteWithdrawalMethod(payload) {
            try {
                await deleteWithdrawalMethod(payload)
                this.connectedMethods = this.connectedMethods.filter((item) => +item.id !== +payload.id)
                eventBus.$emit('showSnackBar', 'Withdrawal method has been deleted', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async getTypes() {
            const { fields, types } = await this.getPaymentsListAvailable()
            this.fields = fields
            this.types = types
        },
        getType(type) {
            return this.types.find(({ key }) => key === type)
        },
        onAddMethod() {
            this.$router.push({ name: 'profile_payment-method-add' })
        },
        async getMethods() {
            this.loading = true
            const data = await this.getPaymentsListConnected()
            // let arr = []
            const arr = data.list.map((item) => {
                const fields = []
                Object.keys(item).forEach((methodKey) => {
                    Object.values(this.fields[item.method]).forEach((field) => {
                        if (methodKey === field.key) {
                            fields.push({
                                title: field.title,
                                value: item[methodKey]
                            })
                        }
                    })
                })
                return {
                    type: item.method,
                    id: item.id,
                    fields
                }
            })
            this.connectedMethods = arr
            this.loading = false
        },
        onEdit(id, type) {
            this.$router.push({ name: 'profile_payment-method-add', params: { editId: id, type } })
        },
        onDelete(id) {
            const title = 'Delete withdrawal method'
            const message = 'Are you sure you want to remove this withdrawal method?'
            this.openConfirmModal(title, message, id)
        },
        openConfirmModal(title, message, id) {
            this.$refs['confirm-modal'].open(title, message)
                .then((res) => {
                    if (res) {
                        this.deleteWithdrawalMethod({ id })
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 576px){
        .wizard__form .wizard__form {
            padding: 0;
            .wizard-payment__radio {
                flex-direction: column;
            }
        }
    }
</style>
